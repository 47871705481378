export default [
    {
        path: '/crm/utenti',
        name: 'crm-utenti-index',
        component: () => import('@/views/crm/utenti/index.vue'),
        meta: {
            pageTitle: 'Utenti',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Utenti',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'PlusIcon',
            breadcrumb_btn_to: {name: 'crm-utenti-new' },
        },
    },
    {
        path: '/crm/utenti/show/:id_riga',
        name: 'crm-utenti-show',
        component: () => import('@/views/crm/utenti/show.vue'),
        meta: {
            pageTitle: 'Scheda Utente',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Utenti',
                to: {name: 'crm-utenti-index' },
              },
              {
                text: 'Scheda Utente',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/utenti/new',
        name: 'crm-utenti-new',
        component: () => import('@/views/crm/utenti/new.vue'),
        meta: {
            pageTitle: 'Nuovo Utente',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Utenti',
                to: {name: 'crm-utenti-index' },
              },
              {
                text: 'Nuovo Utente',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/utenti/test-form',
        name: 'crm-test-form',
        component: () => import('@/views/crm/utenti/test-component-form.vue'),
        meta: {
            pageTitle: 'Test Component Form',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Utenti',
                to: {name: 'crm-utenti-index' },
              },
              {
                text: 'Test Component Form',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/utenti/edit/:id_riga',
        name: 'crm-utenti-edit',
        component: () => import('@/views/crm/utenti/edit.vue'),
        meta: {
            pageTitle: 'Modifica Utente',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Utenti',
                to: {name: 'crm-utenti-index' },
              },
              {
                text: 'Modifica Utente',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/utenti/change-password/:id_riga/:name',
        name: 'crm-utenti-changepassword',
        component: () => import('@/views/crm/utenti/change-password.vue'),
        meta: {
            pageTitle: 'Cambio Password Utente',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Utenti',
                to: {name: 'crm-utenti-index' },
              },
              {
                text: 'Cambio Password Utente',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/leads',
        name: 'crm-leads-index',
        component: () => import('@/views/crm/leads/index.vue'),
        meta: {
            pageTitle: 'Leads',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Leads',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'PlusIcon',
            breadcrumb_btn_to: {name: 'crm-leads-new' },
        },
    },
    {
        path: '/crm/leads/new',
        name: 'crm-leads-new',
        component: () => import('@/views/crm/leads/new.vue'),
        meta: {
            pageTitle: 'Nuovo Lead',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Leads',
                to: {name: 'crm-leads-index' },
              },
              {
                text: 'Nuovo Lead',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/leads/show/:id_riga',
        name: 'crm-leads-show',
        component: () => import('@/views/crm/leads/show.vue'),
        meta: {
            pageTitle: 'Scheda lead',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Leads',
                to: {name: 'crm-leads-index' },
              },
              {
                text: 'Scheda Lead',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/leads/edit/:id_riga',
        name: 'crm-leads-edit',
        component: () => import('@/views/crm/leads/edit.vue'),
        meta: {
            pageTitle: 'Modifica Lead',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Leads',
                to: {name: 'crm-leads-index' },
              },
              {
                text: 'Modifica Lead',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/leads/edit_convert/:id_riga',
        name: 'crm-leads-edit-convert',
        component: () => import('@/views/crm/leads/edit_convert.vue'),
        meta: {
            pageTitle: 'Controlla Lead e Converti in Cliente',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Leads',
                to: {name: 'crm-leads-index' },
              },
            ],
        },
    },
    {
        path: '/crm/customers',
        name: 'crm-customers-index',
        component: () => import('@/views/crm/customers/index.vue'),
        meta: {
            pageTitle: 'Clienti',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Clienti',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/customers/show/:id_riga',
        name: 'crm-customers-show',
        component: () => import('@/views/crm/customers/show.vue'),
        meta: {
            pageTitle: 'Scheda Cliente',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Clienti',
                to: {name: 'crm-customers-index' },
              },
              {
                text: 'Scheda Cliente',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/customers/edit/:id_riga',
        name: 'crm-customers-edit',
        component: () => import('@/views/crm/customers/edit.vue'),
        meta: {
            pageTitle: 'Modifica Cliente',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Clienti',
                to: {name: 'crm-customers-index' },
              },
              {
                text: 'Modifica Cliente',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/licenze',
        name: 'crm-licenze-index',
        component: () => import('@/views/crm/licenze/index.vue'),
        meta: {
            pageTitle: 'Licenze',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Licenze',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/licenze/show/:id_riga',
        name: 'crm-licenze-show',
        component: () => import('@/views/crm/licenze/show.vue'),
        meta: {
            pageTitle: 'Scheda Licenza',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Licenze',
                to: {name: 'crm-licenze-index' },
              },
              {
                text: 'Scheda Licenza',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/payments',
        name: 'crm-pagamenti-index',
        component: () => import('@/views/crm/payments/index.vue'),
        meta: {
            pageTitle: 'Pagamenti',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Pagamenti',
                active: true,
              },
            ],
        },
    },
    {
        path: '/crm/payments/show/:id_riga',
        name: 'crm-pagamenti-show',
        component: () => import('@/views/crm/payments/show.vue'),
        meta: {
            pageTitle: 'Scheda Cliente',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Pagamenti',
                to: {name: 'crm-pagamenti-index' },
              },
              {
                text: 'Scheda Pagamento',
                active: true,
              },
            ],
        },
    },
    
    {
      path: '/crm/invoices',
      name: 'crm-fatture-index',
      component: () => import('@/views/crm/invoices/index.vue'),
      meta: {
          pageTitle: 'Fatture',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Fatture',
              active: true,
            },
          ],
      },
  },
  {
      path: '/crm/invoices/show/:id_riga',
      name: 'crm-fatture-show',
      component: () => import('@/views/crm/invoices/show.vue'),
      meta: {
          pageTitle: 'Riepilogo fattura',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Fatture',
              to: {name: 'crm-fatture-index' },
            },
            {
              text: 'Scheda Fattura',
              active: true,
            },
          ],
      },
  },
]
